import styled from "styled-components";
import { Colors } from "constants/colors";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  background: ${Colors.white};
  border: 1px solid ${Colors.border};
  border-radius: 8px;
  margin-top: 24px;

  @media (max-width: 1050px) {
    display: none;
  }

  div.container-empty {
    width: 100%;
    height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      color: ${Colors.secondary80};
      text-align: center;
    }
  }

  section {
    overflow-x: hidden;

    width: 100%;
    position: relative;

    table {
      th,
      td {
        vertical-align: top;

        div.column {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          strong {
            margin-top: 54px;
          }

          padding-right: 33px;
        }

        strong {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;

          color: ${Colors.secondary80};
        }
      }

      tr.main {
        padding-top: 20px;
      }

      th,
      td,
      tr {
        padding: 5px;
        padding-left: 30px;
      }

      th {
        text-align: left;
      }

      tr:nth-child(even) {
        width: 100%;
        background-color: ${Colors.gray70};
      }
    }

    table {
      width: 100%;
      thead {
        background: ${Colors.secondary50};
        height: 37px;
        padding-left: 30px;
        max-height: 37px;

        tr {
          th {
            color: ${Colors.white};
          }
        }
      }

      tbody {
        tr {
        }
      }
    }
  }
`;

export const SidebarFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  div.card-filter {
    cursor: pointer;
    width: 192px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 28px;

    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;

      display: flex;
      align-items: center;

      color: ${Colors.secondary70};
    }

    span.active {
      font-weight: bold;
    }
  }

  div.active {
    background: ${Colors.gray80};
  }
`;

export const ContainerResults = styled.div`
  padding-left: 47px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CardResult = styled.div`
  width: 194px;
  height: 117px;
  display: flex;
  flex-direction: column;
  background: ${Colors.gray80};

  box-sizing: border-box;
  border-radius: 5px;
`;

interface TrProps {
  active: boolean;
}

export const TrBody = styled.tr<TrProps>`
  height: ${(props) => (props.active ? "117px" : "80px")};
  max-width: 900px;
  width: 100%;
  div.all {
    padding-top: 10px;
    word-break: break-all;
  }

  div.informations {
    display: flex;
    flex-direction: column;
    word-break: break-all;

    strong {
      margin-top: 10px;
    }

    strong.last {
      margin-top: 16px;
    }
  }

  background: ${(props) => (props.active ? Colors.secondary10 : "")} !important;
`;

export const Tr = styled.tr<TrProps>`
  height: ${(props) => (props.active ? "77px" : "77px")};
  max-width: 900px;
  width: 100%;

  div.all {
    padding-top: 10px;
  }
`;

export const TrExtra = styled.tr`
  width: 100%;
  height: 100%;
  background: ${Colors.secondary10} !important;
  border-top: 1px solid ${Colors.secondary1};
  border-bottom: 1px solid ${Colors.secondary1};
  margin-top: 0px;

  th {
    font-weight: 700;
    font-size: 10px;
    line-height: 100%;
    text-transform: uppercase;

    color: ${Colors.border6};
  }
`;

export const ContainerItemsMobile = styled.div`
  max-width: 1180px;
  width: 100%;
  height: 100%;

  @media (min-width: 1050px) {
    display: none;
  }
`;

export const CardMobile = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;

  div.container-empty {
    width: 100%;
    height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      color: ${Colors.secondary80};
      text-align: center;
    }
  }
`;

export const Item = styled.div`
  min-height: 254px;
  width: 100%;
  margin-top: 24px;
  background: ${Colors.white};
  border: 0.6px solid ${Colors.secondary40};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 18px 32px 27px 20px;

  display: flex;
  flex-direction: column;

  div.skeleton {
    margin-top: 8px;
  }

  small {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 9px;
    line-height: 100%;

    color: ${Colors.secondary80};
  }

  h1.first {
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: ${Colors.secondary80};
    margin-top: 8px;
    margin-bottom: 0px;
  }

  h5 {
    margin-top: 16px;
    font-weight: 700;
    font-size: 9px;
    line-height: 100%;
    text-transform: uppercase;
    color: #c4c4c4;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;

    color: ${Colors.secondary80};
    margin-top: 8px;
    margin-bottom: 0px;
  }

  h2 {
    margin-top: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: ${Colors.secondary80};
    margin-bottom: 0px;
  }

  span.text {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;

    color: ${Colors.secondary80};
    margin-top: 8px;
    margin-bottom: 0px;
  }

  span.text-last {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;

    color: ${Colors.secondary80};
    margin-top: 16px;
    margin-bottom: 0px;
  }

  div.chevron-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }

  div.footer-details {
    border-top: 1px solid #f1f1f1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    padding-top: 24px;
  }
`;

export const DropDownFilter = styled.div`
  display: block;
  background: ${Colors.white};
  width: 179px;
  min-height: 123px;
  z-index: -1;
  margin-top: -15px;
  border-radius: 0px 0px 14px 14px;
  border-radius: 0px 0px 14px 14px;

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;
      }
    }

    li.last {
      border: 0;
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const LinkStyled = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 4;
  margin-top: 12px;

  width: 97px;
  height: 28px;

  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 17px;
  background: transparent;

  span {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;

    color: ${Colors.primary100};
  }
`;

export const LinkStyledMobile = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;

  width: 97px;
  height: 28px;

  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 17px;
  background: transparent;

  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${Colors.primary100};
`;

export const ColumnReport = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${Colors.gray50};
`;

export const SalesReport = styled.div`
  width: 100%;
  min-height: 90px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: 1050px) {
    grid-template-columns: repeat(2, 1fr);
  }

  background-color: ${Colors.gray60};
  padding: 30px;
`;
