import useSWR from "swr";

import moment from "moment";

import { TicketCharts } from "types";
import { TotalType } from "types";
import { PurchaseCharts } from "types";

import { authClient } from "../../authClient";

import user_result_icon from "../../../assets/user-result-icon.svg";
import client_result_icon from "../../../assets/cliente-result-icon.svg";
import organizer_result_icon from "../../../assets/organizer-result-icon.svg";
import event_result_icon from "../../../assets/event-result-icon.svg";
import ticket_result_icon from "../../../assets/ticket-result-icon.svg";
import purchase_result_icon from "../../../assets/purchase-result-icon.svg";

import { LastEventType } from "types";

import { HighlightedEventType } from "types";

interface Props {
  filter?: string;
}

export function useAuthTotals<Data = any, Error = any>({ filter }: Props) {
  const url = `/backoffice/dashboard/totals?` + filter;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const { data } = await authClient.get(url);

    const resultData = [
      { name: "Usuários", icon: user_result_icon, total: data?.userTotals },
      {
        name: "Clientes",
        icon: client_result_icon,
        total: data?.userTicketTotals,
      },
      {
        name: "Organizadores",
        icon: organizer_result_icon,
        total: data?.organizerTotals,
      },
      { name: "Eventos", icon: event_result_icon, total: data?.eventTotals },
      {
        name: "Ingressos",
        icon: ticket_result_icon,
        total: data?.eventTicketTotals,
      },
      {
        name: "Nº Vendas",
        icon: purchase_result_icon,
        total: data?.purchaseTicketTotals,
      },
    ];

    return { ...data, results: resultData };
  });

  return { data, error, mutate };
}

export function useAuthTicketCharts<Data = any, Error = any>({
  filter,
}: Props) {
  const url = `/event-ticket/chart?` + filter;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const { data } = await authClient.post(url);

    return data;
  });

  return { data, error, mutate };
}

export function useAuthPurchaseCharts<Data = any, Error = any>({
  filter,
}: Props) {
  const url = `/purchase/chart?` + filter;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.post(url);

    const newMap = response.data.paymentsTypePercentage?.map((total: any) => {
      return {
        data: [
          {
            x: "",
            y: total?.percentage,
          },
        ],
        name: total?.payment_type,
      };
    });

    return newMap ?? [];
  });

  return { data, error, mutate };
}

export function useAuthLastEvents<Data = any, Error = any>() {
  const url = `/backoffice/event/filter?page=1&limit=10`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.data.map((event: LastEventType) => {
      return {
        ...event,
      };
    });

    const newResponse = { ...response.data, data: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

export function useHighlightedtEvents<Data = any, Error = any>() {
  const url = `/backoffice/event/filter?highlight=true&page=1&limit=10`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map(
      (event: HighlightedEventType) => {
        return {
          ...event,
        };
      }
    );

    const newResponse = { ...response.data, data: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

export function useAuthSliderEvents<Data = any, Error = any>() {
  const url = `/backoffice/event/filter?slider=1&page=1&limit=10`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const newMapData = response.data.results.map(
      (event: HighlightedEventType) => {
        return {
          ...event,
        };
      }
    );

    const newResponse = { ...response.data, data: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}
